import { Link } from 'gatsby'
import { FC } from 'react'
import styled from 'styled-components'

import { Title } from '../common/styles'
import Layout from '../layouts'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessTitle = styled(Title)`
  margin-top: 28px;
`

const SuccessPage: FC = () => {
  return (
    <Layout smallNavBar>
      <Container>
        <SuccessTitle>Cette page n'existe pas !</SuccessTitle>
        <Link to='/'>Retourner à l'accueil</Link>
      </Container>
    </Layout>
  )
}

export default SuccessPage
